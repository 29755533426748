import * as React from "react";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import LargeButton from "../components/large-button";

const Page = styled.div``;

const MediaKitContainer = styled.div`
  color: black;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  > div:first-child {
    margin-bottom: 5rem;
  }
`;

const ButtonContainer = styled.div`
  margin: 2rem 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
`;

const MediaKitPage = () => {
  return (
    <Page>
      <Hero backgroundColor="white">
        <MediaKitContainer>
          <div>
            <h2>Media kit</h2>
            <p>Download the Towo Labs logo in PNG and SVG format.</p>
          </div>
          <div>
            <div>
              <StaticImage
                src="../images/Logo.png"
                alt=""
                width={240}
                quality={100}
                placeholder="none"
                imgStyle={{
                  filter: `brightness(1) invert(1)`,
                }}
              />
            </div>
            <ButtonContainer>
              <a
                href="/towo-labs-media-kit.zip"
                download
                title="Download Towo Labs logo ZIP file"
              >
                <LargeButton fontSize=".8rem">
                  <div className="icon-container">
                    <StaticImage
                      src="../images/DownloadIcon.png"
                      alt=""
                      width={20}
                      quality={90}
                      placeholder="none"
                      className="icon main-icon"
                      style={{
                        marginRight: "1rem",
                      }}
                    />

                    <StaticImage
                      src="../images/DownloadIcon.png"
                      alt=""
                      width={20}
                      quality={90}
                      placeholder="none"
                      className="icon hover-icon"
                      style={{
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  </div>
                  Towo Labs logo - ZIP file
                </LargeButton>
              </a>
            </ButtonContainer>
          </div>
        </MediaKitContainer>
      </Hero>

      <Layout title={"Media Kit"}>
        <Footer />
      </Layout>
    </Page>
  );
};

export default MediaKitPage;
